import { Injectable } from '@angular/core';
import { Geolocation, Position } from '@capacitor/geolocation';
import { UserGeoLocation } from '@shared/interfaces/geolocation.interface';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { ComponentHelperService } from '@shared/services/helpers/components/component-helper/component-helper.service';
import { BehaviorSubject } from 'rxjs';
import { Platform } from '@ionic/angular';
@Injectable({
  providedIn: 'root'
})
export class GeolocationService {
  isLocationAvailable = new BehaviorSubject<boolean>(null);
  constructor(
    private diagnostic: Diagnostic,
    private services: ComponentHelperService,
    private platform: Platform
  ) {}

  async getCurrentPosition(): Promise<Position> {
    const coordinates = await Geolocation.getCurrentPosition({
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    });
    return coordinates;
  }

  async getMappedGeoloaction(): Promise<UserGeoLocation> {
    const locationRetrieved = await this.getCurrentPosition();
    if (
      locationRetrieved &&
      locationRetrieved.coords &&
      locationRetrieved.coords.longitude &&
      locationRetrieved.coords.latitude
    ) {
      return {
        longitude: locationRetrieved.coords.longitude.toString(),
        latitude: locationRetrieved.coords.latitude.toString()
      };
    } else {
      return {
        longitude: '',
        latitude: ''
      };
    }
  }

  async onGeolocationChange(): Promise<void> {
    if (this.platform.is('cordova')) {
      // For App intialization
      await this.setLocationStatusOnAppInit();
      // For Status change
      await this.onLocationStatusChange();
    }
  }

  async checkPermissionAndRequest(): Promise<boolean> {
    const permission = await Geolocation.checkPermissions();
    if (permission.location == 'granted') {
      return true;
    } else {
      const requestPermission = await Geolocation.requestPermissions();
      return requestPermission.location != 'denied';
    }
  }

  async setLocationStatusOnAppInit(): Promise<void> {
    const validLocation = await this.isValidLocation();
    this.diagnostic.isGpsLocationAvailable().then((available) => {
      this.isLocationAvailable.next(validLocation && available);
      if (!validLocation || !available) {
        this.services.navigation.navigate(['/gps-lost']);
      }
    });
  }

  async onLocationStatusChange(): Promise<void> {
    this.diagnostic.registerLocationStateChangeHandler((locationStatus) => {
      this.isValidLocation().then((valid) => {
        this.isLocationAvailable.next(
          locationStatus != 'location_off' && valid
        );
        if ((!!locationStatus && locationStatus === 'location_off') || !valid) {
          this.services.navigation.navigate(['/gps-lost']);
        }
      });
    });
  }

  async isValidLocation(): Promise<boolean> {
    const mode = await this.diagnostic.getLocationMode();
    const permission = await this.checkPermissionAndRequest();
    return permission && mode != 'device_only';
  }
}
