import { Injectable, isDevMode } from '@angular/core';
import { Platform } from '@ionic/angular';
import { FirebaseConfig } from '@awesome-cordova-plugins/firebase-config/ngx';
import appConfig from '@assets/config/app-config.json';

export function initializeApp(configurationService: ConfigService): any {
  return () => configurationService.loadStringConfig(Object.keys(appConfig)[0]);
}

export interface AppConfig {
  storeEncryptionKey: string;
}

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private memoObj = {};
  private configuration: AppConfig = {
    storeEncryptionKey: null
  };
  constructor(
    private firebaseConfig: FirebaseConfig,
    private platform: Platform
  ) {}

  public getConfig(): AppConfig {
    return this.configuration;
  }

  public loadStringConfig(configKey: string): Promise<any> {
    if (!this.isDevMode() && this.platform.is('cordova')) {
      return new Promise<void>((resolve) => {
        if (this.memoObj[configKey]) {
          resolve(this.memoObj[configKey]);
          return;
        }

        this.firebaseConfig
          .fetchAndActivate()
          .then(() => {
            this.firebaseConfig
              .getString(configKey)
              .then((configValue: string) => {
                this.configuration[configKey] = configValue;
                this.memoObj[configKey] = configValue;
                resolve();
              })
              .catch(() => {
                this.configuration = appConfig;
                resolve();
              });
          })
          .catch(() => {
            this.configuration = appConfig;
            resolve();
          });
      });
    } else {
      this.configuration = appConfig;
    }
  }

  isDevMode(): boolean {
    return isDevMode();
  }
}
