import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { OrderSummaryUiModel } from '@shared/ui-models/order-summary-ui.model';
import {
  OrderSummaryState,
  OrderSummaryStore
} from '@shared/states/order-summary/order-summary.store';

@Injectable({ providedIn: 'root' })
export class OrderSummaryQuery extends QueryEntity<
  OrderSummaryState,
  OrderSummaryUiModel
> {
  constructor(protected store: OrderSummaryStore) {
    super(store);
  }
}
