import { Injectable } from '@angular/core';
import { BaseHttp } from '@core/http/base.http';
import { APIResponse } from '@core/models/api-response.model';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { DocumentsType } from '@shared/enums/document.type.enum';
import { DocumentsConfigStore } from '@modules/my-documents/shared/states/documents-config/documents-config.store';
import { DocumentsConfigQuery } from '@modules/my-documents/shared/states/documents-config/documents-config-query';
import { DocumentConfigUiModel } from '@modules/my-documents/shared/ui-models/document-config-ui.model';
import { DocumentConfigModel } from '@modules/my-documents/shared/models/document-config.model';
import documentConfigMapper from '@shared/services/mappers/document-config-mapper';

@Injectable({ providedIn: 'root' })
export class MyDocumentsDataService {
  baseUrl = this.http.baseUrl + '/api/document';

  constructor(
    private http: BaseHttp,
    private documentsConfigStore: DocumentsConfigStore,
    private documentsConfigQuery: DocumentsConfigQuery
  ) {}

  getDocumentsConfig(): Observable<DocumentConfigUiModel[]> {
    const url = this.baseUrl + '/configuration';
    const request$ = this.http
      .get<APIResponse<Array<DocumentConfigModel>>>(url)
      .pipe(
        mergeMap((response) => {
          const documentConfigUiModelList = response.data.map(
            (documentConfigModel) => {
              return documentConfigMapper.mapToUiModel(documentConfigModel);
            }
          );
          this.documentsConfigStore.add(documentConfigUiModelList);
          return of(response.data);
        })
      );
    return this.documentsConfigQuery.hasEntity()
      ? of(this.documentsConfigQuery.getAll())
      : request$;
  }

  getDocumentConfigByType(documentType: DocumentsType): DocumentConfigUiModel {
    return this.documentsConfigQuery
      .getAll()
      .filter(
        (DocumentConfigUiModel) =>
          documentType == DocumentConfigUiModel.documentType
      )[0];
  }
}
