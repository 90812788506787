import { Injectable } from '@angular/core';
import { StoreConfig, Store } from '@datorama/akita';
import { createUserInitialState, UserModel } from './user.model';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user' })
export class UserStore extends Store<UserModel> {
  constructor() {
    super(createUserInitialState());
  }
}
