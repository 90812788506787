import { Injectable } from '@angular/core';
import { AuthStore } from './auth.store';
import { Query } from '@datorama/akita';
import { AuthState } from './auth.model';
import { resetStores } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthQuery extends Query<AuthState> {
  isLoggedIn$: Observable<boolean> = this.select(
    (tokens) => !!tokens.access_token && tokens.refresh_token
  ).pipe(
    map((isLoggedIn) => {
      return Boolean(isLoggedIn);
    })
  );

  constructor(protected store: AuthStore) {
    super(store);
  }

  resetData(): void {
    resetStores();
  }
}
