import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LangService {
  constructor(private translatService: TranslateService) {}

  public langChanging(langCode: string): void {
    const direction = langCode === 'ar' ? 'rtl' : 'ltr';
    if (document.getElementsByTagName('html')[0]) {
      document.getElementsByTagName('html')[0].dir = direction;
    }
    localStorage.setItem(environment.langKey, langCode);
    this.translatService.use(langCode);
  }

  public langIntialization(): void {
    const lang =
      localStorage.getItem(environment.langKey) !== null
        ? localStorage.getItem(environment.langKey)
        : 'ar';

    this.translatService.setDefaultLang('ar');
    this.langChanging('ar');
  }

  public get langGetCurrent(): string {
    return localStorage.getItem(environment.langKey) !== null
      ? localStorage.getItem(environment.langKey)
      : 'ar';
  }
}
