import { DocumentConfigModel } from '@modules/my-documents/shared/models/document-config.model';
import { DocumentConfigUiModel } from '@modules/my-documents/shared/ui-models/document-config-ui.model';

export function mapToUiModel(
  documentConfigModel: DocumentConfigModel
): DocumentConfigUiModel {
  return {
    id: documentConfigModel.id,
    documentType: documentConfigModel.documentType,
    isLarge: documentConfigModel.isLarge,
    isSliding: documentConfigModel.isSliding,
    maxUploadCount: documentConfigModel.maxUploadCount,
    isMultiUpload: documentConfigModel.isMultiUpload,
    pageSizeInKB: documentConfigModel.pageSizeInKB,
    isRequired: documentConfigModel.isRequired
  };
}

export default {
  mapToUiModel
};
