import { Injectable } from '@angular/core';
import {
  OrderQueueItem,
  OrderDocument,
  DocumentFile
} from './order-documents-queue.model';
import { OrderDocumentsQueueStore } from './order-documents-queue.store';
import { OrderDocumentsQueueQuery } from './order-documents-queue.query';
import { Observable, of } from 'rxjs';
import { OrdersQuery } from '@modules/orders/shared/states/orders/orders.query';
import { DocumentsConfigQuery } from '@modules/my-documents/shared/states/documents-config/documents-config-query';
import { MyDocumentsDataService } from '@modules/my-documents/shared/services/my-documents-data/my-documents-data.service';
import { mergeMap } from 'rxjs/operators';
import { FileHelperService } from '@shared/services/helpers/files/file.helper.service';
import { Directory } from '@capacitor/filesystem';

@Injectable({ providedIn: 'root' })
export class OrderDocumentsQueueService {
  constructor(
    private orderDocumentsQueueStore: OrderDocumentsQueueStore,
    private orderDocumentsQueueQuery: OrderDocumentsQueueQuery,
    private ordersQuery: OrdersQuery,
    private fileHelperService: FileHelperService,
    private documentConfigQuery: DocumentsConfigQuery,
    private myDocumentsDataService: MyDocumentsDataService
  ) {}

  addDocumentToOrderQueue(orderQueueItem: OrderQueueItem): void {
    const orderRetrieved = this.ordersQuery.getEntity(orderQueueItem.orderId);
    if (orderRetrieved && orderRetrieved.nationalID) {
      orderQueueItem.nationalID = orderRetrieved.nationalID;
    }
    this.orderDocumentsQueueStore.upsert(
      orderQueueItem.orderId,
      orderQueueItem
    );
  }

  getOrders(): Observable<OrderQueueItem[]> {
    return this.orderDocumentsQueueQuery.selectAll();
  }

  getOrdersList(): OrderQueueItem[] {
    return this.orderDocumentsQueueQuery.getAll();
  }

  getOrdersCount(): Observable<number> {
    return this.orderDocumentsQueueQuery.selectCount();
  }

  mapDocumentToUpload(
    orderId: number,
    orderDocument: OrderDocument
  ): Observable<any> {
    return this.myDocumentsDataService.getDocumentsConfig().pipe(
      mergeMap((docsConfig) => {
        const docConfig = docsConfig.find(
          (documentConfig) =>
            documentConfig.documentType == orderDocument.docType
        );
        return of({
          orderId: orderId,
          docType: docConfig ? docConfig.id : null,
          file: orderDocument.files
        });
      })
    );
  }
}
