import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { OrderSummaryUiModel } from '@shared/ui-models/order-summary-ui.model';

export type OrderSummaryState = EntityState<OrderSummaryUiModel>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'OrderSummary' })
export class OrderSummaryStore extends EntityStore<
  OrderSummaryState,
  OrderSummaryUiModel
> {
  constructor() {
    super();
  }
}
