import { Injectable } from '@angular/core';
import { Directory, PermissionStatus } from '@capacitor/filesystem';
import { Plugins } from '@capacitor/core';
const { Filesystem } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class FileHelperService {
  constructor() {}

  getFileSizeInkB(sizeInMegaByte: number): number {
    return Math.ceil(sizeInMegaByte / 1000);
  }

  validFileSize(
    totalSizeOfUploadedFiles: number,
    maxFilesSizeToUpload: number,
    sizeInKB: number
  ): boolean {
    if (
      (sizeInKB || sizeInKB + totalSizeOfUploadedFiles) <= maxFilesSizeToUpload
    ) {
      totalSizeOfUploadedFiles += sizeInKB;
      return true;
    } else {
      false;
    }
  }

  validFileMimeType(validMimeTypes: Array<string>, type: string): boolean {
    return validMimeTypes.find((ex) => ex == type) ? true : false;
  }

  async storeFileToDirectory(
    data: string,
    fileName: string,
    directory: Directory
  ): Promise<any> {
    const newFile = await Filesystem.writeFile({
      data: data,
      path: fileName,
      directory
    });
    return newFile;
  }

  async readFile(uri: string, directory: Directory): Promise<any> {
    try {
      const fileRead = await Filesystem.readFile({
        path: uri,
        directory
      });
      if (fileRead && fileRead.data) {
        fileRead.data.replace('"', '');
        return fileRead.data;
      } else {
        return undefined;
      }
    } catch (err) {
      return undefined;
    }
  }

  async copyFileToDirectory(
    from: string,
    to: string,
    fromDirectory: Directory,
    toDirectory: Directory
  ): Promise<any> {
    const copiedFile = await Filesystem.copy({
      from,
      to,
      fromDirectory,
      toDirectory
    });
    return copiedFile;
  }

  async deleteFileFromDirectory(
    path: string,
    directory: Directory
  ): Promise<any> {
    await Filesystem.deleteFile({
      path,
      directory
    });
  }

  async requestPermissions(): Promise<PermissionStatus> {
    return await Filesystem.requestPermissions();
  }

  async checkPermissions(): Promise<PermissionStatus> {
    return await Filesystem.checkPermissions();
  }

  async getFileInfo(fileName: string, directory: Directory): Promise<void> {
    const fileStat = await Filesystem.stat({
      directory: directory,
      path: fileName
    });
    console.log('getFileInfo ~ fileStat', fileStat);
  }
}
