import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpClient
} from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from '@core/authentication/auth.service';
import { AuthState } from '@core/authentication/state/auth.model';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.handle(request, next));
  }

  async handle(request: HttpRequest<any>, next: HttpHandler): Promise<any> {
    const authSate = await this.authService.getAuthToken();

    if (authSate.access_token && !request.url.includes('/connect/token')) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + authSate.access_token
        }
      });
    }

    return next
      .handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            //TODO: call  trackError in tracking service
            // not authenticated user
            return this.refreshToken(next, request);
          }
          return throwError(error);
        })
      )
      .toPromise();
  }

  refreshToken(next: HttpHandler, request: HttpRequest<any>): Observable<any> {
    return from(this.authService.refreshToken()).pipe(
      mergeMap((authState: AuthState) => {
        request = request.clone({
          headers: request.headers.set(
            'Authorization',
            'Bearer ' + authState.access_token
          )
        });

        return next.handle(request).pipe(
          catchError((error: HttpErrorResponse) => {
            return throwError(error);
          })
        );
      })
    );
  }
}
