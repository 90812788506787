import { Injectable } from '@angular/core';
import { StoreConfig, Store } from '@datorama/akita';
import { AuthState, createInitialState } from './auth.model';

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'auth',
  resettable: true
})
export class AuthStore extends Store<AuthState> {
  constructor() {
    super(createInitialState());
  }
}
