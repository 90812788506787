import { Injectable } from '@angular/core';
import { StatusBar, StatusBarPlugin, Style } from '@capacitor/status-bar';

@Injectable({
  providedIn: 'root'
})
export class StatusBarService {
  statusBar: StatusBarPlugin = StatusBar;
  constructor() {}

  async setOverlaysWebView(doesOverlay: boolean): Promise<void> {
    return await this.statusBar.setOverlaysWebView({ overlay: doesOverlay });
  }

  setStyleLight(): void {
    this.statusBar.setStyle({ style: Style.Light });
  }

  setStyleDark(): void {
    this.statusBar.setStyle({ style: Style.Dark });
  }

  setStyleDefault(): void {
    this.statusBar.setStyle({ style: Style.Default });
  }

  async hideStatusBar(): Promise<void> {
    return await this.statusBar.hide();
  }

  async showStatusBar(): Promise<void> {
    return await this.statusBar.show();
  }
  backgroundColorByHexString(color: string): void {
    this.statusBar.setBackgroundColor({ color: color });
  }

  // Base Colors
  setStatusbarPrimary(): void {
    const primaryColor = this.getFromPalette('primary');
    this.backgroundColorByHexString(primaryColor);
    this.setStyleDark();
  }

  setStatusbarSecondary(): void {
    const secondaryColor = this.getFromPalette('secondary');
    this.backgroundColorByHexString(secondaryColor);
    this.setStyleDark();
  }

  setStatusbarTertiary(): void {
    const tertiaryColor = this.getFromPalette('tertiary');
    this.backgroundColorByHexString(tertiaryColor);
    this.setStyleDefault();
  }

  setStatusbarSuccess(): void {
    const successColor = this.getFromPalette('success');
    this.backgroundColorByHexString(successColor);
    this.setStyleDefault();
  }

  setStatusbarDanger(): void {
    const dangerColor = this.getFromPalette('danger');
    this.backgroundColorByHexString(dangerColor);
    this.setStyleDark();
  }

  setStatusbarWarning(): void {
    const warningColor = this.getFromPalette('warning');
    this.backgroundColorByHexString(warningColor);
    this.setStyleDefault();
  }

  setStatusbarDark(): void {
    const darkColor = this.getFromPalette('dark');
    this.backgroundColorByHexString(darkColor);
    this.setStyleDark();
  }

  setStatusbarMedium(): void {
    const mediumColor = this.getFromPalette('medium');
    this.backgroundColorByHexString(mediumColor);
    this.setStyleDark();
  }

  setStatusbarLight(): void {
    const lightColor = this.getFromPalette('light');
    this.backgroundColorByHexString(lightColor);
    this.setStyleDefault();
  }

  setStatusbarLightShade(): void {
    const lightShadeColor = this.getFromPalette('light-shade');
    this.backgroundColorByHexString(lightShadeColor);
    this.setStyleDefault();
  }

  setStatusbarWhite(): void {
    const whiteColor = this.getFromPalette('white');
    this.backgroundColorByHexString(whiteColor);
    this.setStyleDefault();
  }

  /**
   *
   * @param color the base color as we are familiar with like [ primary | secondary | success ]
   * @returns the color value from our pallete then removed the spaces if it exist
   */
  getFromPalette(color: string): string {
    return getComputedStyle(document.documentElement)
      .getPropertyValue(`--ion-color-${color}`)
      .replace(/\s/g, '');
  }
}
