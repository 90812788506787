import { CameraPreviewOptions } from '@capacitor-community/camera-preview';
import {
  CameraDirection,
  CameraResultType,
  CameraSource,
  ImageOptions
} from '@capacitor/camera';

export const CAMERA_PREVIEW_OPTIONS: CameraPreviewOptions = {
  x: 0,
  y: 0,
  width: window.innerWidth,
  height: window.screen.height,
  position: 'rear',
  toBack: true
};

export const CAMERA_OPTIONS: ImageOptions = {
  quality: 80,
  allowEditing: false,
  resultType: CameraResultType.Base64,
  direction: CameraDirection.Rear,
  source: CameraSource.Camera
};
