import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoggedInGuard } from '@core/guards/loggedin.guard';
import { NotLoggedInGuard } from '@core/guards/not-loggedin.guard';
import { FawryUserGuard } from '@core/guards/fawry-user.guard';
import { CourierUserGuard } from '@core/guards/courier-user.gurad';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/login/login.module').then((m) => m.LoginModule),
    canActivate: [NotLoggedInGuard],
    data: {
      pageName: 'username'
    }
  },
  {
    path: 'payment',
    loadChildren: () =>
      import('./modules/payment/payment.module').then(
        (m) => m.PaymentPageModule
      ),
    canActivate: [LoggedInGuard],
    data: {
      customBackNavigation: true,
      route: '/order-summary/user-verification'
    }
  },
  {
    path: 'confirm-payment',
    loadChildren: () =>
      import('./modules/confirm-payment/confirm-payment.module').then(
        (m) => m.ConfirmPaymentPageModule
      ),
    canActivate: [LoggedInGuard],
    data: {
      customBackNavigation: true,
      route: '/orders'
    }
  },
  {
    path: 'orders',
    loadChildren: () =>
      import('./modules/orders/orders.module').then((m) => m.OrdersPageModule),
    canActivate: [LoggedInGuard, CourierUserGuard],
    data: {
      pageName: 'orders'
    }
  },
  {
    path: 'orders-fawry',
    loadChildren: () =>
      import('./modules/orders-fawry/orders-fawry.module').then(
        (m) => m.OrdersFawryPageModule
      ),
    canActivate: [LoggedInGuard, FawryUserGuard],
    data: {
      pageName: 'orders-fawry'
    }
  },
  {
    path: 'order-summary',
    loadChildren: () =>
      import('./modules/order-summary/order-summary.module').then(
        (m) => m.OrderSummaryPageModule
      ),
    canActivate: [LoggedInGuard],
    data: {
      pageName: 'order-summary'
    }
  },
  {
    path: 'my-documents',
    loadChildren: () =>
      import('./modules/my-documents/my-documents.module').then(
        (m) => m.IdentityDocumentsPageModule
      ),
    canActivate: [LoggedInGuard],
    data: {
      pageName: 'identity-documents'
    }
  },
  {
    path: 'server-error',
    loadChildren: () =>
      import('./shared/modules/server-error/server-error.module').then(
        (m) => m.ServerErrorPageModule
      ),
    data: {
      disableDeviceBackBtn: true,
      pageName: 'server-error'
    }
  },
  {
    path: 'gps-lost',
    loadChildren: () =>
      import('./shared/modules/gps-lost/gps-lost.module').then(
        (m) => m.GPSLostPageModule
      ),
    data: {
      disableDeviceBackBtn: true,
      pageName: 'gps-lost'
    }
  },
  {
    path: 'root-detection',
    loadChildren: () =>
      import('./shared/pages/root-detection/root-detection.module').then(
        (m) => m.RootDetectionPageModule
      )
  },
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
