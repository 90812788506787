import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from '@core/authentication/auth.service';
import { MfNavigationService } from '@shared/services/helpers/routes/navigation/mf-navigation.service';

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private mfNvaigation: MfNavigationService
  ) {}

  async canActivate(): Promise<boolean> {
    if (!(await this.authService.isUserAuthenticated())) {
      //TODO : configure this url somehow
      this.mfNvaigation.navigateByUrl('auth');
    }
    return true;
  }
}
