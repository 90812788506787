import { Component } from '@angular/core';
import { LangService } from '@shared/services/helpers/platform/lang/lang.service';
import { TrackingService } from '@shared/services/helpers/tracking/tracking.service';
import { StatusBarService } from '@shared/services/helpers/platform/status-bar/status-bar.service';
import { PlatformService } from '@shared/services/helpers/platform/platform/platform.service';
import { GeolocationService } from '@shared/services/helpers/platform/geolocation/geolocation.service';
import { AppDataService } from '@shared/services/appData/app-data.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private langService: LangService,
    private trackingService: TrackingService,
    private statusBar: StatusBarService,
    private platform: PlatformService,
    private geolocationService: GeolocationService,
    private appDataService: AppDataService
  ) {
    this.langService.langIntialization();
    this.trackingService.initializeTracking();
    this.platform.platformReady().then(() => {
      if (this.platform.isPlatformIs('android')) {
        this.statusBar.setStatusbarLightShade();
        this.geolocationService.onGeolocationChange();
      }
      // disable rooting for penetration test
      if (false) {
        this.appDataService.checkIsRootedDevice();
      }
    });
  }
}
