import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';

import { ErrorHandleService } from '@shared/services/helpers/validations/error-handle/error-handle.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandleInterceptor implements HttpInterceptor {
  constructor(private errorHandleService: ErrorHandleService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        //Extract the endpoint url
        // const cleanUrl = error.url
        //   .replace(environment.apiUrl, '')
        //   .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '_');

        if (error.status >= 500 || error.status === 0) {
          // Adding 0 to handle time out error
          //TODO : call trackError in trackingService
          // this.trackingService.trackError(
          //   `${TrackErrorPrefix.ErrorRequest}_${error.status}${cleanUrl}`
          // );
          //TODO : check handleServerErrorNavigation in errorHandleService
          // this.errorHandleService.handleServerErrorNavigation();
        } else if (error.status != 401) {
          //TODO : call trackError in trackingService
          // this.trackingService.trackError(
          //   `${TrackErrorPrefix.ErrorRequestLow}_${error.status}${cleanUrl}`
          // );
        }
        return throwError(error.error);
      })
    );
  }
}
