import { Injectable } from '@angular/core';
import {
  NavigationEnd,
  Router,
  ActivationStart,
  Data,
  Event,
  UrlTree,
  UrlSegmentGroup,
  UrlSegment,
  PRIMARY_OUTLET,
  Navigation
} from '@angular/router';
import { NavController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { App as CapacitorApp } from '@capacitor/app';
import { RoutesConfigService } from '@shared/services/helpers/routes/routes-config/routes-config.service';

@Injectable({
  providedIn: 'root'
})
export class MfNavigationService {
  private previousUrl: string;
  private currentUrl: string;

  private routeData: Data;
  navigationEnd = new BehaviorSubject(null);
  routeDataSubject = new BehaviorSubject<Data>(null);
  swipeGestures = new BehaviorSubject<boolean>(true);

  constructor(
    private router: Router,
    private navCtrl: NavController,
    private routesConfigService: RoutesConfigService
  ) {
    this.currentUrl = this.router.url;
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        this.navigationEnd.next(event);
        this.routeDataSubject.next(this.routeData);
      }
      this.snapshotRouteData(event); // Needed for detect if there's a custom navigation
    });

    this.trackHardBackButton(); // Needed for handle the hard back button in android
  }

  /**
   * Recieve the data passed through the route
   * Store the route config data for handling custom navigation
   */
  snapshotRouteData(event: Event): void {
    if (event instanceof ActivationStart) {
      if (event.snapshot.data) {
        this.routeData = event.snapshot.data;
        if (
          this.routeData.disableDeviceBackBtn ||
          this.routeData.customBackNavigation
        ) {
          this.swipeGestures.next(false);
        } else {
          this.swipeGestures.next(true);
        }
      }
    }
  }

  /**
   * Handlde the hard back button behavior
   * By default will act same as the backarrow in backdrop
   * In case we set the `disableDeviceBackBtn` by true in route config, then will stop back navigate
   */
  trackHardBackButton(): void {
    CapacitorApp.addListener('backButton', ({ canGoBack }) => {
      if (this.routesConfigService.checkCurrentPageIsRootLevel()) {
        CapacitorApp.exitApp();
      } else if (this.routeData && !this.routeData.disableDeviceBackBtn) {
        this.goBack();
      }
    });
  }

  setRoot(url = ''): void {
    this.previousUrl = null;
    this.navCtrl.navigateRoot(url);
  }

  navigate(url: any[], data = null, queryParams = null): void {
    this.router.navigate(url, { state: data, queryParams: queryParams });
  }

  navigateWithSkipLocationChange(url: any[], data = null): void {
    this.router.navigate(url, { state: data, replaceUrl: true });
  }

  navigateByUrl(url: string): void {
    this.router.navigateByUrl(url);
  }

  canGoBack(): boolean {
    return this.getPreviousUrl() ? true : false;
  }

  public getPreviousUrl(): string {
    return this.previousUrl;
  }

  public getCurrentUrl(): string {
    return this.currentUrl;
  }

  /**
   * Go back to previous Url (Previous in stack)
   * Allow to custom the back navigation in case we set our route config
   * Set `customBackNavigation` and `route` for custom the default behavior
   */
  goBack(forceBack = false): void {
    if (
      this.routeData &&
      this.routeData.customBackNavigation &&
      this.routeData.route
    ) {
      this.navigate([this.routeData.route]);
    } else if (
      !this.routeData ||
      !this.routeData.disableDeviceBackBtn ||
      forceBack
    ) {
      this.navCtrl.pop();
    }
  }

  setRootWithUrl(url: string | string[]): void {
    this.previousUrl = null;
    this.navCtrl.navigateRoot(url);
  }

  getCurrentNavigation(): Navigation {
    return this.router.getCurrentNavigation();
  }

  isStateExist(): {
    [k: string]: any;
  } {
    return (
      this.router.getCurrentNavigation() &&
      this.router.getCurrentNavigation().extras.state
    );
  }

  getStateIfExist(): {
    [k: string]: any;
  } {
    if (
      this.router.getCurrentNavigation() &&
      this.router.getCurrentNavigation().extras.state
    ) {
      return this.router.getCurrentNavigation().extras.state;
    }
  }

  getModuleRootPath(): string {
    return this.router?.routerState?.snapshot?.root?.firstChild?.routeConfig
      ?.path;
  }

  setRootWithReloadAPP(): void {
    window.location.assign('/');
  }

  getUrlSegments(): UrlSegment[] {
    const tree: UrlTree = this.router.parseUrl(this.router.url);
    const urlSegmentGroup: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
    const segments: UrlSegment[] = urlSegmentGroup?.segments;

    return segments;
  }

  getModuleName(): string {
    if (this.getUrlSegments()?.length > 0) {
      const segments = this.getUrlSegments().map((segment) => segment.path);
      const moduleName = segments[0] ?? 'root';

      return moduleName;
    } else {
      return 'root';
    }
  }

  getPageName(): string {
    if (this.getUrlSegments()?.length > 0) {
      const segments = this.getUrlSegments().map((segment) => segment.path);
      const pageName = segments[1] ?? 'root';

      return pageName;
    } else {
      return 'root';
    }
  }

  setLoggedInUserRoot(): void {
    //TODO : to be loaded from routes config
    this.setRoot('/orders');
  }

  editRouteData(routeData: Data): void {
    if (this.routeData) {
      this.routeData = routeData;
    }
  }
}
