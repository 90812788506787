import { UserType } from '@shared/enums/user-type.enum';
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { UserStore } from '@shared/states/user/user.store';
import { MfNavigationService } from '@shared/services/helpers/routes/navigation/mf-navigation.service';

@Injectable({
  providedIn: 'root'
})
export class FawryUserGuard implements CanActivate {
  constructor(
    private userStore: UserStore,
    private mfNavigation: MfNavigationService
  ) {}

  canActivate(): boolean {
    if (this.userStore.getValue().type === UserType.FAWRY) {
      return true;
    }

    this.mfNavigation.navigateByUrl('/orders');
  }
}
