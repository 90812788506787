import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { mergeMap } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { LangService } from '@shared/services/helpers/platform/lang/lang.service';
import { PlatformService } from '@shared/services/helpers/platform/platform/platform.service';

@Injectable({
  providedIn: 'root'
})
export class AppHeadersInterceptor implements HttpInterceptor {
  constructor(
    private langService: LangService,
    private platformService: PlatformService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.addHeadersToRequest(request)).pipe(
      mergeMap((request) => {
        return next.handle(request);
      })
    );
  }

  private async addHeadersToRequest(
    request: HttpRequest<any>
  ): Promise<HttpRequest<any>> {
    const mfRequest = request.url.includes(environment.apiUrl);
    if (mfRequest) {
      request = this.setLangugaeHeader(request);
      // TODO: Find a solution for getInfo() from App plugin
      // request = await this.setAppVersionHeader(request);
    }
    request = this.setContentTypeHeader(request);
    return request;
  }
  private setContentTypeHeader(request: HttpRequest<any>): HttpRequest<any> {
    // neglect-headers is used to prevent overriding content-type header as we use form-data in upload-service.ts
    if (
      !request.headers.has('Content-Type') &&
      !request.headers.has('neglect-headers')
    ) {
      request = request.clone({
        setHeaders: {
          'content-type': 'application/json',
          Accept: 'application/json'
        }
      });
    }
    return request;
  }
  private setLangugaeHeader(request: HttpRequest<any>): HttpRequest<any> {
    request = request.clone({
      setHeaders: {
        'Accept-Language': this.langService.langGetCurrent
      }
    });
    return request;
  }

  // private async setAppVersionHeader(
  //   request: HttpRequest<any>
  // ): Promise<HttpRequest<any>> {
  //   const version: string =
  //     sessionStorage.getItem('appVersion') ?? (await this.getAppVersion());
  //   if (!!version) {
  //     request = request.clone({
  //       setHeaders: {
  //         'App-Version': version.toString()
  //       }
  //     });
  //   }
  //   return request;
  // }

  // private getAppVersion(): Promise<string> {
  //   return new Promise((resolve) => {
  //     this.platformService.platformReady().then(() => {
  //       if (this.platformService.isPlatformIs('cordova')) {
  //         this.platformService.getAppVersion().then((appVersionNumber) => {
  //           resolve(appVersionNumber);
  //           sessionStorage.setItem('appVersion', appVersionNumber);
  //         });
  //       } else {
  //         resolve(null);
  //       }
  //     });
  //   });
  // }
}
