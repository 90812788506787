export enum ConversionEvents {
  CourierTest = 'Courier_test'
}

export enum FirebaseConversions {
  Courier_test = 'Courier_test'
}

// Add Events here whith this structure EX:
// SHARED: {
//     initializeApp: 'initializeApp'
//   },
export const CUSTOM_EVENTS = {
  USER_NAME: {
    login_page_entered: 'login_page_entered'
  },
  ORDERS: {
    orders_page_entered: 'orders_page_entered',
    order_card_clicked: 'order_card_clicked',
    search_input_clicked: 'search_input_clicked'
  },
  ORDER_SUMMARY: {
    order_summary_page_entered: 'order_summary_page_entered',
    start_button_clicked: 'start_button_clicked',
    call_user_button_clicked: 'call_user_button_clicked',
    update_order_button_clicked: 'update_order_button_clicked'
  },
  CANCEL_ORDER: {
    cancel_order_clicked: 'cancel_order_clicked'
  },
  USER_OTP_CODE: {
    success_code: 'success_code',
    invalid_code: 'invalid_code'
  }
};

export interface TrackToolError {
  tool: string;
  error?: string;
  success: boolean;
  successMsg?: string;
}

export const TrackingTools = {
  Amplitude: 'amplitude',
  Firebase: 'firebase'
};

export enum TrackErrorPrefix {
  ErrorAuth = 'erauth',
  ErrorRequest = 'erreq',
  ErrorRequestLow = 'erreqlow',
  ErrorJs = 'erjs'
}

export enum RevenueType {
  Purchase = 'purchase',
  Cost = 'cost',
  Tax = 'tax',
  Refund = 'refund'
}

export interface TrackPurchaseOptions {
  productId?: string;
  quantity: number;
  price: number;
  revenueType?: RevenueType;
  eventProperties?: unknown;
}

export interface TrackEventConfig {
  disablePageName: boolean;
}
