import { Injectable } from '@angular/core';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import {
  ConversionEvents,
  FirebaseConversions,
  TrackingTools,
  TrackPurchaseOptions,
  TrackToolError
} from '@shared/config/tracking.config';

@Injectable({
  providedIn: 'root'
})
export class FirebaseAnalyticsService {
  constructor() {}

  setUserId(userId: string): void {
    if (FirebaseAnalytics.setUserId) {
      FirebaseAnalytics.setUserId({ userId: userId });
    }
  }

  setUserProperties(params: { [key: string]: string }): void {
    for (const [key, value] of Object.entries(params)) {
      FirebaseAnalytics.setUserProperty({
        name: key,
        value: value
      });
    }
  }

  trackEvent(
    eventName: string,
    data?: { [key: string]: string },
    fullEventNameParam = false
  ): Promise<TrackToolError> {
    return new Promise((resolve, reject) => {
      const mappedEventName = this.shortenEventName(eventName);
      if (fullEventNameParam && this.splitEventName(eventName)?.length > 40) {
        data.eventName = eventName;
      }
      if (FirebaseAnalytics?.logEvent) {
        try {
          FirebaseAnalytics.logEvent({ name: mappedEventName, params: data })
            .then(() => {
              resolve({
                success: true,
                successMsg: `${TrackingTools.Firebase} trackEvent success`,
                tool: TrackingTools.Firebase
              });
            })
            .catch((error) => {
              console.error('trackEvent error =>> ', error);
              reject({
                success: false,
                error: `trackEvent_${error}`,
                tool: TrackingTools.Firebase
              });
            });
        } catch (err) {
          reject({
            success: false,
            error: `trackEvent_${err}`,
            tool: TrackingTools.Firebase
          });
        }
      } else {
        reject({
          success: false,
          error: 'trackEvent_general_error',
          tool: TrackingTools.Firebase
        });
      }
    });
  }

  trackPage(pageName: string): Promise<TrackToolError> {
    return new Promise((resolve, reject) => {
      try {
        FirebaseAnalytics.setScreenName({ screenName: pageName })
          .then(() => {
            resolve({
              success: true,
              successMsg: `${TrackingTools.Firebase} trackPage success`,
              tool: TrackingTools.Firebase
            });
          })
          .catch((err) => {
            console.error('trackPage error =>> ', err);
            reject({
              success: false,
              error: `trackPage_${err}`,
              tool: TrackingTools.Firebase
            });
          });
      } catch (err) {
        reject({
          success: false,
          error: `trackPage_${err}`,
          tool: TrackingTools.Firebase
        });
      }
    });
  }

  trackPurchase(product: TrackPurchaseOptions): Promise<TrackToolError> {
    return new Promise((resolve, reject) => {
      try {
        this.trackEvent('purchase', {
          value: product.price ? product.price.toString() : '0',
          currency: 'EGP'
        }).catch((err: TrackToolError) => {
          console.error('trackPurchase error =>> ', err);
          reject({
            success: false,
            error: `trackPurchase_${err && err.error}`,
            tool: TrackingTools.Firebase
          });
        });
      } catch (err) {
        reject({
          success: false,
          error: `trackPurchase_${err}`,
          tool: TrackingTools.Firebase
        });
      }
    });
  }

  trackConversion(
    eventType: ConversionEvents,
    data?: { [key: string]: string }
  ): Promise<TrackToolError> {
    return new Promise((resolve, reject) => {
      try {
        if (!!eventType && FirebaseConversions[eventType]) {
          this.trackEvent(FirebaseConversions[eventType], data).catch(
            (err: TrackToolError) => {
              console.error('trackConversion error =>> ', err);
              reject({
                success: false,
                error: `trackConversion_${err && err.error}`,
                tool: TrackingTools.Firebase
              });
            }
          );
        } else {
          reject({
            success: false,
            error: 'trackConversion_no_event_provided',
            tool: TrackingTools.Firebase
          });
        }
      } catch (err) {
        reject({
          success: false,
          error: `trackConversion_${err}`,
          tool: TrackingTools.Firebase
        });
      }
    });
  }

  private shortenEventName(eventName: string): string {
    if (!!eventName) {
      let copiedEventName = this.splitEventName(eventName);
      return (copiedEventName =
        copiedEventName.length > 40
          ? copiedEventName.substring(0, 40)
          : copiedEventName);
    }
  }
  private splitEventName(eventName: string): string {
    if (!!eventName) {
      const splittedEventName = eventName.split('-').join('_');
      return splittedEventName;
    }
  }
}
