import { biMapOrderStatus } from '@shared/enums/order-status.enum';
import { mappedOrderType } from '@modules/order-summary/shared/enums/order-type.enum';
import { OrderDetailsModel } from '@shared/models/order-details.model';
import { OrderDetailsUiModel } from '@shared/ui-models/order-details-ui.model';

export function mapToUiModel(
  orderDetailsModel: OrderDetailsModel
): OrderDetailsUiModel {
  return {
    id: orderDetailsModel.id,
    orderDetails: {
      status: biMapOrderStatus(
        orderDetailsModel.orderDetails?.status
      ).toString(),
      type: mappedOrderType[orderDetailsModel.orderDetails?.type],
      amount: orderDetailsModel.orderDetails.amount,
      verifyToken: orderDetailsModel.orderDetails.verifyToken
    },
    clientDetails: {
      name: orderDetailsModel.clientDetails?.name,
      nationalID: orderDetailsModel.clientDetails?.nationalID,
      phone: orderDetailsModel.clientDetails?.phone,
      city: orderDetailsModel.clientDetails?.city,
      area: orderDetailsModel.clientDetails?.area,
      addressDetails: orderDetailsModel.clientDetails?.addressDetails,
      landmark: orderDetailsModel.clientDetails?.landmark,
      floor: orderDetailsModel.clientDetails?.floor,
      apartment: orderDetailsModel.clientDetails?.apartment
    }
  };
}
export default {
  mapToUiModel
};
