import { Injectable } from '@angular/core';
import {
  StoreConfig,
  EntityStore,
  EntityState,
  persistState
} from '@datorama/akita';
import { OrderQueueItem } from './order-documents-queue.model';
import { Storage } from '@capacitor/storage';

export type OrderDocumentsQueueState = EntityState<OrderQueueItem>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'order-documents-queue', idKey: 'orderId' })
export class OrderDocumentsQueueStore extends EntityStore<OrderDocumentsQueueState> {
  constructor() {
    super();
  }
}

persistState({
  include: ['order-documents-queue'],
  key: 'orderQueueStore',
  storage: {
    setItem: async (key: string, value) => {
      return await Storage.set({
        key: key,
        value: JSON.stringify(value)
      });
    },
    getItem: async (key: string) => {
      const result = await Storage.get({ key });
      return result.value;
    },

    clear: async () => {
      await Storage.clear();
    }
  }
});
