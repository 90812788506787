import { Injectable } from '@angular/core';
import { MfNavigationService } from '@shared/services/helpers/routes/navigation/mf-navigation.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ComponentHelperService {
  translate: TranslateService;
  navigation: MfNavigationService;

  constructor(
    translateService: TranslateService,
    navigation: MfNavigationService
  ) {
    this.translate = translateService;
    this.navigation = navigation;
  }
}
