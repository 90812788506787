import { Checkout } from '@modules/payment/shared/interfaces/checkout.interface';
import { PaymentModal } from '@shared/models/checkout.model';
import { PaymentUiModel } from '@shared/ui-models/checkout-ui.model';
import { CardItem } from '@shared/components/core-ui/details/interfaces/details-data.interface';

export function mapToUiModel(paymentModel: PaymentModal): PaymentUiModel {
  return {
    title: paymentModel.title,
    hint: paymentModel.hint,
    checkoutList: mapCheckoutListToUiModel(paymentModel.checkoutList)
  };
}

function mapCheckoutListToUiModel(checkout: Checkout[]): CardItem[] {
  const mapCheckoutToUiModel = checkout?.map((checkout: Checkout) => {
    return {
      itemTitle: checkout.payment,
      isBoldItemTitle: checkout.isTotal,
      itemDesc:
        Intl.NumberFormat('en-GB', {
          style: 'decimal',
          maximumFractionDigits: 0
        }).format(checkout.amount) + ' ج.م'
    };
  });
  return mapCheckoutToUiModel;
}

export default {
  mapToUiModel
};
