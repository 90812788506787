import { DocumentsType } from '@shared/enums/document.type.enum';
import { UserGeoLocation } from '@shared/interfaces/geolocation.interface';
import { OrderDocumentsQueueState } from './order-documents-queue.store';

export interface OrderQueueItem {
  orderId: number;
  nationalID: string;
  orderDocuments: Array<OrderDocument>;
  verificationLocation: UserGeoLocation;
  orderFees: OrderFees;
}

export interface OrderDocument {
  docType: DocumentsType;
  status: OrderDocumentStatus;
  files: Array<DocumentFile>;
}

export interface DocumentFile {
  page: number;
  // data: string;
  fileUri: string;
  fileName: string;
  sizeInKB: string;
  mimeType: string;
}

export enum OrderDocumentStatus {
  pending = 'pending',
  inprogress = 'inprogress',
  uploaded = 'uploaded',
  failed = 'failed'
}

export interface OrderDocumentsQueue {
  orderId: number;
  orderDocuments: Array<OrderDocument>;
}

export interface OrderFees {
  amount: number;
  isPaid: boolean;
}

export function createInitialState(): OrderDocumentsQueueState {
  return {
    orderId: null,
    orderDocuments: []
  };
}
