import { UpdateOrderStatusModel } from '@shared/models/update-order-status.model';
import { UpdateOrderStatusUiModel } from '@shared/ui-models/update-order-status.model';

export function mapToModel(
  orderUiModel: UpdateOrderStatusUiModel
): UpdateOrderStatusModel {
  return {
    orderId: orderUiModel.orderId,
    status: orderUiModel.status,
    comment: null,
    latitude: orderUiModel.latitude,
    longitude: orderUiModel.longitude
  };
}
export default {
  mapToModel
};
