import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import {
  OrderDocumentsQueueStore,
  OrderDocumentsQueueState
} from './order-documents-queue.store';

@Injectable({ providedIn: 'root' })
export class OrderDocumentsQueueQuery extends QueryEntity<OrderDocumentsQueueState> {
  constructor(protected store: OrderDocumentsQueueStore) {
    super(store);
  }
}
