import { Injectable } from '@angular/core';
import { OrderDocumentsQueueQuery } from '@shared/states/order-documents-queue/order-documents-queue.query';
import { of, Observable, BehaviorSubject, Subject, from, forkJoin } from 'rxjs';
import {
  concatMap,
  mergeMap,
  catchError,
  filter,
  delay,
  switchMap,
  map
} from 'rxjs/operators';
import { OrderDocumentsQueueStore } from '@shared/states/order-documents-queue/order-documents-queue.store';
import { tap } from 'rxjs/operators';
import {
  OrderDocument,
  OrderDocumentStatus,
  OrderQueueItem
} from '@shared/states/order-documents-queue/order-documents-queue.model';
import { OrderDocumentsQueueService } from '@shared/states/order-documents-queue/order-documents-queue.service';
import { BaseHttp } from '@core/http/base.http';
import { SyncOrderStatus } from '@shared/enums/sync-orders-status.enum';
import { PlatformService } from '../helpers/platform/platform/platform.service';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { OrderDataService } from './order-data/order-data.service';
import { OrderStatus } from '@shared/enums/order-status.enum';
import { OrdersStore } from '@modules/orders/shared/states/orders/orders.store';
import { FileHelperService } from '../helpers/files/file.helper.service';
import { Directory } from '@capacitor/filesystem';

@Injectable({
  providedIn: 'root'
})
export class SyncOrdersService {
  baseUrl = this.http.baseUrl + '/api';
  ordersListSize = 0;
  uploadingDocumentStatus: BehaviorSubject<SyncOrderStatus> =
    new BehaviorSubject(SyncOrderStatus.NothingToSync);

  OrderSynced: Subject<number> = new Subject();

  constructor(
    private http: BaseHttp,
    private orderDocumentsQueueQuery: OrderDocumentsQueueQuery,
    private orderDocumentsQueueStore: OrderDocumentsQueueStore,
    private ordersStore: OrdersStore,
    private orderDocumentsQueueService: OrderDocumentsQueueService,
    private orderDataService: OrderDataService,
    private platform: PlatformService,
    private network: Network,
    private fileHelperService: FileHelperService
  ) {
    this.setUploadDocumentStatus();

    this.platform.platformReady().then(() => {
      if (this.platform.isPlatformIs('capacitor')) {
        this.network.onChange().subscribe((status) => {
          if (status == 'connected') {
            if (this.ordersListSize != 0) {
              this.startOrdersSync();
            }
          }
        });
      }
    });
  }

  setUploadDocumentStatus(): void {
    if (this.orderDocumentsQueueQuery.getCount() != 0) {
      this.uploadingDocumentStatus.next(SyncOrderStatus.ErrorDuringUpload);
    }
    this.listenToOrderQueueSize();
  }

  listenToOrderQueueSize(): void {
    this.orderDocumentsQueueQuery.selectCount().subscribe((size) => {
      if (size > this.ordersListSize) {
        this.ordersListSize = size;
        this.startOrdersSync();
      }
      this.ordersListSize = size;
    });
  }

  startOrdersSync(): void {
    if (
      this.uploadingDocumentStatus.getValue() != SyncOrderStatus.UploadingNow
    ) {
      this.uploadingDocumentStatus.next(SyncOrderStatus.UploadingNow);
      this.syncNotUploadedDocuments().subscribe(
        (res) => {
          console.log('syncNotUploadedDocuments subscriber', res);
        },
        (err) => {
          console.log('syncNotUploadedDocuments subscriber error', err);
        },
        () => {
          if (
            this.uploadingDocumentStatus.getValue() ==
            SyncOrderStatus.ErrorDuringUpload
          ) {
            this.uploadingDocumentStatus.next(
              SyncOrderStatus.UploadFinishedWithError
            );
          } else {
            this.uploadingDocumentStatus.next(
              SyncOrderStatus.UploadedSuccessfuly
            );
            // incase documents added while syncing
            if (this.orderDocumentsQueueQuery.getCount() != 0) {
              this.startOrdersSync();
            }
          }
        }
      );
    }
  }

  syncNotUploadedDocuments(): Observable<any> {
    const orders = this.orderDocumentsQueueQuery.getAll();
    return orders.length == 0
      ? of(null)
      : of(...orders).pipe(
          concatMap((data, orderIndex) => {
            return of(...data.orderDocuments).pipe(
              filter(
                (orderDocumentItem: OrderDocument) =>
                  orderDocumentItem.status != OrderDocumentStatus.uploaded
              ),
              concatMap((orderDocument) => {
                const documentIndex = orders[
                  orderIndex
                ].orderDocuments.findIndex(
                  (document) => document.docType == orderDocument.docType
                );

                return this.tryUploadOrderDocument(
                  orderDocument,
                  orders[orderIndex],
                  documentIndex
                );
              }),
              delay(1000)
            );
          })
        );
  }

  tryUploadOrderDocument(
    orderDocument: OrderDocument,
    order: OrderQueueItem,
    documentIndex: number
  ): any {
    const uploadDocumentsEndpoint = this.baseUrl + '/document/upload';

    this.setOrderDocumentStatus(
      order.orderId,
      documentIndex,
      OrderDocumentStatus.inprogress
    );
    return this.orderDocumentsQueueService
      .mapDocumentToUpload(order.orderId, orderDocument)
      .pipe(
        mergeMap((documentToUpload: any) => {
          const filesArr = documentToUpload.file.map((file) =>
            this.fileHelperService.readFile(
              file && file.fileUri
                ? file.fileUri.substring(file.fileUri.lastIndexOf('/') + 1)
                : '',
              Directory.Data
            )
          );
          return forkJoin(filesArr.map((fileFn) => from(fileFn))).pipe(
            map((fileData) => ({
              ...documentToUpload,
              file: documentToUpload.file.map((fileEntry, index) => ({
                ...fileEntry,
                data: fileData && fileData[index] ? fileData[index] : ''
              }))
            }))
          );
        })
      )
      .pipe(
        mergeMap((documentToUpload) => {
          return this.http.post(uploadDocumentsEndpoint, documentToUpload).pipe(
            mergeMap((uploadResponse) => {
              this.setOrderDocumentStatus(
                order.orderId,
                documentIndex,
                OrderDocumentStatus.uploaded
              );

              if (this.allOrderDocumentsUploadedSuccessfully(order.orderId)) {
                order.orderDocuments.forEach((orderDocument) => {
                  orderDocument.files.forEach((file) => {
                    this.fileHelperService.deleteFileFromDirectory(
                      file && file.fileUri
                        ? file.fileUri.substring(
                            file.fileUri.lastIndexOf('/') + 1
                          )
                        : '',
                      Directory.Data
                    );
                  });
                });
                //call mark order status completed
                return this.markOrderCompleted(order);
              } else {
                return of(uploadResponse);
              }
            })
          );
        }),
        catchError((error) => {
          this.uploadingDocumentStatus.next(SyncOrderStatus.ErrorDuringUpload);
          this.setOrderDocumentStatus(
            order.orderId,
            documentIndex,
            OrderDocumentStatus.failed
          );
          return of(error);
        }),
        delay(1000)
      );
  }

  allOrderDocumentsUploadedSuccessfully(orderId: number): boolean {
    const orderDocuments =
      this.orderDocumentsQueueQuery.getEntity(orderId).orderDocuments;
    for (let i = 0; i < orderDocuments.length; i++) {
      if (orderDocuments[i].status != OrderDocumentStatus.uploaded)
        return false;
    }
    return true;
  }

  markOrderCompleted(order: OrderQueueItem): Observable<unknown> {
    return this.orderDataService
      .updateOrderStatus({
        orderId: order.orderId,
        status: OrderStatus.Signed,
        latitude:
          order && order.verificationLocation
            ? order.verificationLocation.latitude
            : '',
        longitude:
          order && order.verificationLocation
            ? order.verificationLocation.longitude
            : ''
      })
      .pipe(
        tap((x) => {
          this.removeOrder(order.orderId);
          this.OrderSynced.next(order.orderId);
        })
      );
  }

  setOrderDocumentStatus(
    orderId: number,
    documentIndex: number,
    orderDocumentStatus: OrderDocumentStatus
  ): void {
    this.orderDocumentsQueueStore.update(orderId, (order) => {
      const orderDocs = JSON.parse(JSON.stringify(order.orderDocuments));
      orderDocs[documentIndex].status = orderDocumentStatus;
      return {
        orderDocuments: orderDocs
      };
    });
  }

  removeOrder(orderId: number): void {
    this.orderDocumentsQueueStore.remove(orderId);
    this.ordersStore.remove(orderId);
  }

  resetUploadingDocumentStatus(): void {
    this.uploadingDocumentStatus.next(SyncOrderStatus.NothingToSync);
  }
}
