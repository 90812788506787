import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  RouteConfig,
  RouteData,
  ROUTES_CONFIG
} from 'src/app/config/routes.config';

@Injectable({
  providedIn: 'root'
})
export class RoutesConfigService {
  constructor(private router: Router) {}

  getRouteConfigByPageName(PAGE_NAME: string): RouteConfig {
    return ROUTES_CONFIG.find((route) => route.PAGE_NAME == PAGE_NAME);
  }

  getRouteConfigByURL(ROUTE_URL: string): RouteConfig {
    // if url contains numbers
    if (/\d+/.test(ROUTE_URL)) {
      // remove numbers
      ROUTE_URL = ROUTE_URL.replace(/[0-9]/g, '');
      // remove '/' at last index
      ROUTE_URL = ROUTE_URL.substring(0, ROUTE_URL.length - 1);
    }

    return ROUTES_CONFIG.find((route) => route.ROUTE_URL == ROUTE_URL);
  }

  setCompleteDistinationURLByPageName(
    PAGE_NAME: string,
    DISTINATION_URL: string
  ): void {
    const routeConfig: RouteConfig = ROUTES_CONFIG.find(
      (route) => route.PAGE_NAME == PAGE_NAME
    );
    const routeData: RouteData = routeConfig.ROUTE_DATA
      ? routeConfig.ROUTE_DATA
      : {};
    routeConfig.ROUTE_DATA = routeData;
    routeData.completeDistinationURL = DISTINATION_URL;
  }

  setSkipDistinationURLByPageName(
    PAGE_NAME: string,
    DISTINATION_URL: string
  ): void {
    const routeConfig: RouteConfig = ROUTES_CONFIG.find(
      (route) => route.PAGE_NAME == PAGE_NAME
    );
    const routeData: RouteData = routeConfig.ROUTE_DATA
      ? routeConfig.ROUTE_DATA
      : {};
    routeConfig.ROUTE_DATA = routeData;
    routeData.skipDistinationURL = DISTINATION_URL;
  }

  checkCurrentPageIsRootLevel(): boolean {
    const routeConfig = this.getRouteConfigByURL(this.router.url);
    const pageName = routeConfig && routeConfig?.PAGE_NAME;
    const isRootLevelPage = pageName && routeConfig.IS_ROOT_LEVEL;
    return isRootLevelPage;
  }
}
