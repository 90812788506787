export enum OrderStatus {
  ReadyForDelivery = 0,
  OutForDelivery = 1,
  Delivering = 2,
  Signed = 3,
  Approved = 4,
  Canceled = 5
}

export const mappedOrderStatus = {
  [OrderStatus.OutForDelivery]: 'قيد التوصيل',
  [OrderStatus.Delivering]: 'في الطريق',
  [OrderStatus.Canceled]: 'الغاء'
};

export function biMapOrderStatus(
  key: OrderStatus | string
): OrderStatus | string {
  const valueToKey = Object.keys(mappedOrderStatus).reduce(
    (acc, cur) => ({
      ...acc,
      [mappedOrderStatus[cur]]: cur
    }),
    {}
  );
  return mappedOrderStatus[key] || valueToKey[key];
}
