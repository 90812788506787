import { Injectable, isDevMode } from '@angular/core';
import { Data } from '@angular/router';
import { Platform } from '@ionic/angular';
import {
  ConversionEvents,
  TrackEventConfig,
  TrackPurchaseOptions
} from '@shared/config/tracking.config';
import { MfNavigationService } from '@shared/services/helpers/routes/navigation/mf-navigation.service';
import { AmplitudeService } from '@shared/services/helpers/tracking/amplitude/amplitude.service';
import { FirebaseAnalyticsService } from '@shared/services/helpers/tracking/firebase-analytics/firebase-analytics.service';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  private pageName: string;

  constructor(
    private platform: Platform,
    private navigationService: MfNavigationService,
    private amplitudeService: AmplitudeService,
    private firebaseAnalyticsService: FirebaseAnalyticsService
  ) {}

  initializeTracking(): void {
    if (!this.isDevMode() && this.platform.is('cordova')) {
      this.amplitudeService.initAmplitudeSdk();

      this.navigationService.routeDataSubject.subscribe((routeData: Data) => {
        if (routeData && routeData.pageName) {
          this.pageName = routeData.pageName;
          this.trackPage(routeData.pageName);
        } else {
          this.pageName = this.navigationService.getPageName();
          this.trackPage(this.navigationService.getPageName());
        }
      });
    }
  }

  /** This method used to set user ID for all tracking tools
   * @param  {string} userId
   * @returns void
   */
  setUserId(userId: string): void {
    if (!this.isDevMode() && this.platform.is('cordova')) {
      this.amplitudeService.setUserId(userId);
      this.firebaseAnalyticsService.setUserId(userId);
    }
  }

  /**
   * This method used to set user properties for tracking tools
   * @param  {{[key:string]:string}} params
   * @returns void
   */
  setUserProperties(params: { [key: string]: string }): void {
    if (!this.isDevMode() && this.platform.is('cordova')) {
      this.amplitudeService.setUserProperties(params);
      this.firebaseAnalyticsService.setUserProperties(params);
    }
  }

  /**
   * This method used to track anonymous users before login to the app
   * @returns void
   */
  setAnonymousUser(): void {
    if (!this.isDevMode() && this.platform.is('cordova')) {
      this.amplitudeService.setAnonymousUser();
    }
  }

  /**
   * This method used to track custom events to any tracking tool
   * @param  {string} eventName
   * @param  {{[key:string]:string}} data?
   * @param  {TrackEventConfig} config?
   * @returns void
   */
  trackEvent(
    eventName: string,
    data?: { [key: string]: string },
    config?: TrackEventConfig
  ): void {
    if (!this.isDevMode() && this.platform.is('cordova')) {
      const pageName = data?.pageName ?? this.pageName;

      if (config && config.disablePageName) {
        eventName = eventName;
      } else {
        eventName = `${pageName}_${eventName}`;
      }

      this.amplitudeService.trackEvent(eventName, data);
      this.firebaseAnalyticsService.trackEvent(eventName, data);
    }
  }

  /**
   * This method used to track page name/route
   * Called by defualt inside #initializeTracking() method
   * @param  {string} pageName
   * @returns void
   */
  trackPage(pageName: string): void {
    if (!this.isDevMode() && this.platform.is('cordova')) {
      this.amplitudeService.trackPage(pageName);
      this.firebaseAnalyticsService.trackPage(pageName);
    }
  }

  /**
   * This method used to track purchase events to any tracking tool
   * @param  {TrackPurchaseOptions} product
   * @returns void
   */
  trackPurchase(product: TrackPurchaseOptions): void {
    if (!this.isDevMode() && this.platform.is('cordova')) {
      this.amplitudeService.trackPurchase(product);
      this.firebaseAnalyticsService.trackPurchase(product);
    }
  }

  /** This method used to track conversion events to any tracking tool
   * Through custom interface {ConversionEvents}
   * @param  {ConversionEvents} eventType
   * @param  {{[key:string]:string}} data
   * @returns void
   */
  trackConversion(
    eventType: ConversionEvents,
    data?: { [key: string]: string }
  ): void {
    if (!this.isDevMode() && this.platform.is('cordova')) {
      this.firebaseAnalyticsService.trackConversion(eventType, data);
    }
  }

  /**
   * @description This method used to log HTTP/JS errors
   * @param  {string} eventName
   * @param  {{[key:string]:string}} data?
   * @param  {TrackEventConfig} config?
   */
  trackError(eventName: string, data?: { [key: string]: string }): void {
    if (!this.isDevMode() && this.platform.is('cordova')) {
      this.firebaseAnalyticsService.trackEvent(eventName, data);
    }
  }

  /**
   * @param  {string} eventName
   * @param  {{[key:string]:string}} data?
   * @param  {TrackEventConfig} config?
   * @returns void
   */
  trackPlatformState(
    eventType: ConversionEvents,
    data?: { [key: string]: string }
  ): void {
    if (!this.isDevMode() && this.platform.is('cordova')) {
      this.trackConversion(eventType, data);
    }
  }

  isDevMode(): boolean {
    return isDevMode();
  }
}
