import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { DocumentConfigUiModel } from '@modules/my-documents/shared/ui-models/document-config-ui.model';

export type DocumentsConfigState = EntityState<DocumentConfigUiModel>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'documents-config' })
export class DocumentsConfigStore extends EntityStore<
  DocumentsConfigState,
  DocumentConfigUiModel
> {
  constructor() {
    super();
  }
}
