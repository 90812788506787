import { Injectable, isDevMode } from '@angular/core';
import { Platform } from '@ionic/angular';
import { ComponentHelperService } from '@shared/services/helpers/components/component-helper/component-helper.service';

declare let IRoot: any;

@Injectable({
  providedIn: 'root'
})
export class AppDataService {
  constructor(
    private platform: Platform,
    private services: ComponentHelperService
  ) {}

  checkIsRootedDevice(): void {
    if (!isDevMode() && this.platform.is('cordova')) {
      if (!!IRoot) {
        IRoot?.isRooted((isRooted: boolean) => {
          isRooted && this.services.navigation.setRoot('/root-detection');
        });
      }
    }
  }
}
