import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import {
  TrackingTools,
  TrackPurchaseOptions,
  TrackToolError
} from '@shared/config/tracking.config';
import amplitude from 'amplitude-js';

@Injectable({
  providedIn: 'root'
})
export class AmplitudeService {
  constructor() {}

  initAmplitudeSdk(): Promise<TrackToolError> {
    return new Promise((resolve, reject) => {
      try {
        console.log(
          'environment.amplitude.apiKey',
          environment.amplitude.apiKey
        );

        amplitude.getInstance().init(environment.amplitude.apiKey);
        resolve({ success: true, tool: TrackingTools.Amplitude });
      } catch (err) {
        reject({
          success: false,
          error: `initAmplitudeSdk_error:${err}`,
          tool: TrackingTools.Amplitude
        });
      }
    });
  }

  setUserId(userId: string): void {
    amplitude.getInstance().setUserId(userId);
  }

  setUserProperties(params: { [key: string]: string }): void {
    amplitude.getInstance().setUserProperties(params);
  }

  setAnonymousUser(): void {
    amplitude.getInstance().setUserId(null);
    amplitude.getInstance().regenerateDeviceId();
  }

  trackEvent(
    eventName: string,
    data?: { [key: string]: string }
  ): Promise<TrackToolError> {
    console.log('eventName', eventName);

    return new Promise((resolve, reject) => {
      const errorCallBack = (status, response) => {
        const error = `status:${status}_error:${response}`;
        console.log('amplitude error', error);

        reject({
          success: false,
          error: `trackEvent_${error}`,
          tool: TrackingTools.Amplitude
        });
      };
      const successCallBack = (status, response) => {
        console.log(`trackEvent success status:${status} response:${response}`);

        resolve({
          tool: TrackingTools.Amplitude,
          success: true,
          successMsg: `${TrackingTools.Amplitude} trackEvent success status:${status} response:${response}`
        });
      };
      amplitude
        .getInstance()
        .logEvent(eventName, data, successCallBack, errorCallBack);
    });
  }

  trackPage(pageName: string): Promise<TrackToolError> {
    return new Promise((resolve, reject) => {
      const errorCallBack = (status, response) => {
        const error = `status:${status}_error:${response}`;
        reject({
          success: false,
          error: `trackPage_${error}`,
          tool: TrackingTools.Amplitude
        });
      };
      const successCallBack = (status, response) => {
        resolve({
          tool: TrackingTools.Amplitude,
          success: true,
          successMsg: `${TrackingTools.Amplitude} trackPage success status:${status} response:${response}`
        });
      };
      amplitude
        .getInstance()
        .logEvent(`pageview:${pageName}`, null, successCallBack, errorCallBack);
    });
  }

  trackPurchase(product: TrackPurchaseOptions): Promise<TrackToolError> {
    return new Promise((resolve, reject) => {
      const revenue = new amplitude.Revenue()
        .setProductId(product.productId)
        .setPrice(product.price ? product.price : 0)
        .setQuantity(product.quantity ? product.quantity : 1);
      try {
        amplitude.getInstance().logRevenueV2(revenue);
        resolve({
          tool: TrackingTools.Amplitude,
          success: true,
          successMsg: `${TrackingTools.Amplitude} trackPurchase success`
        });
      } catch (err) {
        reject({
          success: false,
          error: `trackPurchase_${err}`,
          tool: TrackingTools.Amplitude
        });
      }
    });
  }

  setOptOut(turnOffLogging = false): void {
    amplitude.getInstance().setOptOut(turnOffLogging);
  }
}
