export interface RouteData {
  preload?: boolean;
  delay?: number;
  disableDeviceBackBtn?: boolean;
  customBackNavigation?: boolean;
  route?: string;
  completeDistinationURL?: string;
  skipDistinationURL?: string;
}
export interface RouteConfig {
  PAGE_NAME: string;
  ROUTE_URL: string;
  ROUTE_DATA?: RouteData;
  IS_ROOT_LEVEL?: boolean;
}
export const ROUTES_CONFIG: Array<RouteConfig> = [
  {
    PAGE_NAME: 'orders',
    ROUTE_URL: '/orders',
    IS_ROOT_LEVEL: true
  },
  {
    PAGE_NAME: 'login',
    ROUTE_URL: '/auth/login',
    IS_ROOT_LEVEL: true
  }
];
