import { Injectable } from '@angular/core';
// import { App, AppInfo } from '@capacitor/app';
import { Platform } from '@ionic/angular';
import { Platforms } from '@ionic/core';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {
  constructor(private platform: Platform) {}

  platformReady(): Promise<string> {
    return this.platform.ready();
  }

  isPlatformIs(platformName: Platforms): boolean {
    return this.platform.is(platformName);
  }

  platformBackButton(): any {
    return this.platform.backButton;
  }

  // TODO: Find a solution for getInfo() from App plugin
  // async getAppVersion(): Promise<string> {
  //   const appInfo: AppInfo = await App.getInfo();
  //   return appInfo.version;
  // }
}
