import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { DocumentConfigUiModel } from '@modules/my-documents/shared/ui-models/document-config-ui.model';
import {
  DocumentsConfigState,
  DocumentsConfigStore
} from './documents-config.store';

@Injectable({ providedIn: 'root' })
export class DocumentsConfigQuery extends QueryEntity<
  DocumentsConfigState,
  DocumentConfigUiModel
> {
  constructor(protected store: DocumentsConfigStore) {
    super(store);
  }
}
