import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { OrderUiModel } from '@modules/orders/shared/ui-models/orders-ui.model';

export type OrderState = EntityState<OrderUiModel>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'orders' })
export class OrdersStore extends EntityStore<OrderState, OrderUiModel> {
  constructor() {
    super();
  }
}
