import { UserType } from '@shared/enums/user-type.enum';
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { UserStore } from '@shared/states/user/user.store';
import { MfNavigationService } from '@shared/services/helpers/routes/navigation/mf-navigation.service';

@Injectable({
  providedIn: 'root'
})
export class CourierUserGuard implements CanActivate {
  constructor(
    private userStore: UserStore,
    private mfNavigation: MfNavigationService
  ) {}

  canActivate(): boolean {
    const userType = this.userStore.getValue().type;
    if (userType === UserType.Courier || userType === null) {
      return true;
    }
    this.mfNavigation.navigateByUrl('/orders-fawry');
  }
}
