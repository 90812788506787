import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BaseHttp {
  baseUrl = environment.apiUrl;

  constructor(public httpClient: HttpClient) {}

  get<T>(url: string, options = {}): Observable<T> {
    return this.httpClient.get<T>(url, options);
  }

  post<T>(url: string, body: unknown, options = {}): Observable<T> {
    return this.httpClient.post<T>(url, body, options);
  }

  put<T>(url: string, body: unknown): Observable<T> {
    return this.httpClient.put<T>(url, body);
  }

  delete<T>(url: string): Observable<T> {
    return this.httpClient.delete<T>(url);
  }
}
