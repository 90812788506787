export interface AuthState {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  scope: string;
  token_type: string;
  isNew?: boolean;
}

export interface AuthError {
  error: AuthErrorType;
  error_description?: string;
  blocked_until?: string;
}

export interface VerifyPhone {
  resend_token: string;
  verify_token?: string;
}

export enum AuthErrorType {
  invalidGrant = 'invalid_grant',
  unauthorizedUser = 'unauthorized_client'
}

export interface UserData {
  firstName: string;
  lastName: string;
  email: string;
  avatarUrl: string;
}

export function createInitialState(): AuthState {
  return {
    access_token: '',
    expires_in: null,
    refresh_token: '',
    scope: '',
    token_type: ''
  };
}
