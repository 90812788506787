import { UserType } from '@shared/enums/user-type.enum';

export interface UserModel {
  id: number;
  name: string;
  userName: string;
  password: string;
  phone: string;
  city: number;
  area: number;
  address: string;
  type: UserType;
  nationalId: string;
}

export function createUserInitialState(): UserModel {
  return {
    id: null,
    name: null,
    userName: null,
    password: null,
    phone: null,
    city: null,
    area: null,
    address: null,
    type: null,
    nationalId: null
  };
}
