import { Injectable } from '@angular/core';
import { StatusBarService } from '@shared/services/helpers/platform/status-bar/status-bar.service';
import { ComponentHelperService } from '@shared/services/helpers/components/component-helper/component-helper.service';
import {
  CAMERA_OPTIONS,
  CAMERA_PREVIEW_OPTIONS
} from '../config/camera-preview-options.config';
import { PreviewFrameSizeRatio } from '../enums/preview-frame-size-ratio.enum';
import { DocumentContent } from '../interfaces/document-content.interface';
import { Plugins } from '@capacitor/core';
const { CameraPreview } = Plugins;
import { PlatformService } from '@shared/services/helpers/platform/platform/platform.service';
import { DocumentsType } from '@shared/enums/document.type.enum';
import {
  SCANNER_DEFAULT_MIMETYPE,
  SCANNER_JPEG_MIMETYPE
} from '../config/scanner-default-mimetype.config';
import {
  DocumentFile,
  OrderDocument,
  OrderQueueItem
} from '@shared/states/order-documents-queue/order-documents-queue.model';
import { OrderSummaryQuery } from '@shared/states/order-summary/order-summary.query';
import { OrderDocumentsQueueService } from '@shared/states/order-documents-queue/order-documents-queue.service';
import { Camera, Photo } from '@capacitor/camera';

@Injectable()
export class ScannerHelperService {
  documentContent: DocumentContent;
  orderQueue: OrderQueueItem = {
    orderId: null,
    nationalID: null,
    verificationLocation: null,
    orderFees: null,
    orderDocuments: []
  };

  constructor(
    private services: ComponentHelperService,
    private statusbarService: StatusBarService,
    private platformService: PlatformService,
    private orderSummaryQuery: OrderSummaryQuery,
    private orderDocumentsQueueService: OrderDocumentsQueueService
  ) {}

  getDocumentContent(type: DocumentsType): DocumentContent {
    switch (type) {
      case DocumentsType.NationalId:
        this.documentContent = {
          title: this.services.translate.instant(
            `Pages.ScanningPage.Scanning${type}.Title`
          ),
          inst_msg: this.services.translate.instant(
            `Pages.ScanningPage.Scanning${type}.InstructionMessage`
          ),
          scanning_messages: [
            this.services.translate.instant(
              `Pages.ScanningPage.Scanning${type}.ScanningFrontMessage`
            ),
            this.services.translate.instant(
              `Pages.ScanningPage.Scanning${type}.ScanningBackMessage`
            )
          ]
        };
        break;
      case DocumentsType.CustomerPhoto:
      case DocumentsType.ContractPhoto:
      case DocumentsType.KYCPhoto:
        this.documentContent = {
          title: this.services.translate.instant(
            'Pages.ScanningPage.Scanning' + type + '.Title'
          ),
          inst_msg: null,
          scanning_messages: []
        };
        break;
      default:
        break;
    }

    return this.documentContent;
  }

  getScanningFrameWidthToHeightRatio(
    documentType: DocumentsType
  ): PreviewFrameSizeRatio {
    switch (documentType) {
      case DocumentsType.NationalId: {
        return PreviewFrameSizeRatio.CARD;
      }
      case DocumentsType.ContractPhoto:
      case DocumentsType.KYCPhoto: {
        return PreviewFrameSizeRatio.A4;
      }
      case DocumentsType.CustomerPhoto: {
        return PreviewFrameSizeRatio.CUSTOMER_PHOTO;
      }
      default: {
        return PreviewFrameSizeRatio.CARD;
      }
    }
  }

  async startCameraPreview(): Promise<void> {
    if (this.platformService.isPlatformIs('capacitor')) {
      await this.statusbarService.setOverlaysWebView(true);
      await this.statusbarService.hideStatusBar();
      CameraPreview.start(CAMERA_PREVIEW_OPTIONS);
    }
  }

  async stopCameraPreview(): Promise<void> {
    if (this.platformService.isPlatformIs('capacitor')) {
      await this.statusbarService.showStatusBar();
      await this.statusbarService.setOverlaysWebView(false);
      CameraPreview.stop();
    }
  }

  async takePhotoByCamera(): Promise<Photo> {
    return await Camera.getPhoto(CAMERA_OPTIONS);
  }

  //TODO: Enhance the file name
  getFileToUpload(
    croppedPhoto: Photo,
    fileUri: string,
    scanningResult: OrderDocument
  ): DocumentFile {
    const pageNumber = scanningResult.files.length + 1;
    return {
      page: pageNumber,
      // data: croppedPhoto.base64String,
      fileUri,
      mimeType:
        scanningResult.docType === DocumentsType.ContractPhoto ||
        scanningResult.docType === DocumentsType.KYCPhoto
          ? SCANNER_JPEG_MIMETYPE
          : SCANNER_DEFAULT_MIMETYPE,
      fileName: `${scanningResult.docType}-${pageNumber}`,
      sizeInKB: this.getFileSize(croppedPhoto.base64String)
    };
  }

  addDocumentsToOrderQueueInMemory(
    orderId: number,
    scanningResult: OrderDocument
  ): OrderQueueItem {
    this.orderQueue.orderId = orderId;

    const sameDocumentTypeIndex = this.orderQueue.orderDocuments.findIndex(
      (document) => document.docType === scanningResult.docType
    );

    // In case update the same doc type
    if (sameDocumentTypeIndex != -1) {
      this.orderQueue.orderDocuments.splice(
        sameDocumentTypeIndex,
        1,
        scanningResult
      );
    } else {
      this.orderQueue.orderDocuments.push(scanningResult);
    }

    return this.orderQueue;
  }

  resetOrderQueueInMemory(): void {
    this.orderQueue = {
      orderId: null,
      nationalID: null,
      verificationLocation: null,
      orderFees: null,
      orderDocuments: []
    };
  }

  getFileSize(base64String: string): string {
    const beginningOfString = base64String.startsWith('data:image/png;base64,')
      ? 'data:image/png;base64,'
      : 'data:image/jpeg;base64,';
    const stringLength = base64String.length - beginningOfString.length;
    const sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
    const sizeInKb = Math.ceil(sizeInBytes / 1000);
    return sizeInKb.toString();
  }

  // commented as we are storing file uri not file anymore
  // RemoveFirstPartOfBase64(orderQueueItem: OrderQueueItem): OrderQueueItem {
  //   orderQueueItem.orderDocuments.forEach((documentType) => {
  //     documentType.files.forEach((file) => {
  //       file.data = file.data.startsWith('data:image/png;base64,')
  //         ? file.data.split('data:image/png;base64,')[1]
  //         : file.data;
  //     });
  //   });
  //   return orderQueueItem;
  // }

  addGeoLocationToQueue(orderQueueItem: OrderQueueItem): OrderQueueItem {
    const orderSummary = this.orderSummaryQuery.getEntity(
      orderQueueItem.orderId
    );
    orderQueueItem.verificationLocation = orderSummary?.verificationLocation;
    return orderQueueItem;
  }

  addOrderFeesToQueue(
    orderQueueItem: OrderQueueItem,
    isPaid = true
  ): OrderQueueItem {
    const orderSummary = this.orderSummaryQuery.getEntity(
      orderQueueItem.orderId
    );
    orderQueueItem.orderFees = {
      amount: orderSummary?.orderDetails?.amount,
      isPaid: isPaid
    };

    return orderQueueItem;
  }

  addDocumentsToOrderQueueStore(isFeesPaid?: boolean): void {
    // this.orderQueue = this.RemoveFirstPartOfBase64(this.orderQueue);
    this.addGeoLocationToQueue(this.orderQueue);
    this.addOrderFeesToQueue(this.orderQueue, isFeesPaid);
    this.orderDocumentsQueueService.addDocumentToOrderQueue(this.orderQueue);
    this.resetOrderQueueInMemory();
  }
}
