import { APP_INITIALIZER } from '@angular/core';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import player, { LottiePlayer } from 'lottie-web';
import { LottieModule } from 'ngx-lottie';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandleInterceptor } from '@core/interceptros/error-handle.interceptor';
import { AuthInterceptor } from '@core/interceptros/auth.interceptor';
import { AppHeadersInterceptor } from '@core/interceptros/app-headers.interceptor';
import { ErrorHandleService } from '@shared/services/helpers/validations/error-handle/error-handle.service';
import { ConfigService, initializeApp } from './core/config/config.service';
import { SyncOrdersService } from '@shared/services/data/sync-orders.service';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { FirebaseConfig } from '@awesome-cordova-plugins/firebase-config/ngx';
import { ScannerHelperService } from '@modules/my-documents/pages/scanner/shared/services/scanner-helper.service';
import { PlatformService } from '@shared/services/helpers/platform/platform/platform.service';

export function HttpLoaderFactory(http: HttpClient): unknown {
  return new TranslateHttpLoader(http, './assets/translations/', '.json');
}
// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory(): LottiePlayer {
  return player;
}
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ hardwareBackButton: false }),
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
    }),
    LottieModule.forRoot({ player: playerFactory })
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandleInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHeadersInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandleService
    },
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ConfigService, PlatformService],
      multi: true
    },
    SyncOrdersService,
    Network,
    Diagnostic,
    FirebaseConfig,
    ScannerHelperService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
