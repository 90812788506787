import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { akitaConfig, persistState } from '@datorama/akita';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import SimpleCrypto from 'simple-crypto-js';
import { ConfigService } from './app/core/config/config.service';
import { PlatformService } from '@shared/services/helpers/platform/platform/platform.service';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((res) => {
    const configService = res.injector.get(ConfigService);
    const platformService = res.injector.get(PlatformService);
    const appConfig = configService.getConfig();
    const secretKey = appConfig.storeEncryptionKey;
    const simpleCrypto = new SimpleCrypto(secretKey);
    if (platformService.isPlatformIs('capacitor')) {
      persistState({
        include: ['auth', 'user', 'OrderSummary', 'orders']
        // deserialize: function (data) {
        //   const isEmptyStore = Object.keys(JSON.parse(data)).length === 0;
        //   if (!isEmptyStore) {
        //     const decryptedString: any = data;
        //     //removing double quotes
        //     const cleanDecryptedObject: any = simpleCrypto.decrypt(
        //       decryptedString.slice(1, -1)
        //     );
        //     return cleanDecryptedObject;
        //   } else {
        //     return JSON.parse(data);
        //   }
        // },
        // serialize: function (data) {
        //   const encryptedString = simpleCrypto.encrypt(data);
        //   return JSON.stringify(encryptedString);
        // }
      });
      akitaConfig({ resettable: true });
    } else {
      persistState({
        include: ['auth', 'user', 'OrderSummary', 'orders']
      });
      akitaConfig({ resettable: true });
    }
  })
  .catch((err) => console.log(err));
