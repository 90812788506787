import { Injectable } from '@angular/core';
import { BaseHttp } from '@core/http/base.http';
import { APIResponse } from '@core/models/api-response.model';
import { ID } from '@datorama/akita';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// Models
import { PaymentModal } from '@shared/models/checkout.model';
import { OrderDetailsModel } from '@shared/models/order-details.model';
import { ResendSmsModel } from '@shared/models/resend-sms.model';
// Ui-Models
import { PaymentUiModel } from '@shared/ui-models/checkout-ui.model';
import { OrderDetailsUiModel } from '@shared/ui-models/order-details-ui.model';
import { OrderSummaryUiModel } from '@shared/ui-models/order-summary-ui.model';
import { UpdateOrderStatusUiModel } from '@shared/ui-models/update-order-status.model';
import { MarkOrderInProgressUiModel } from '@modules/order-summary/shared/ui-models/mark-in-progress-ui.model';
// Mappers
import orderDetailsMapper from '@shared/services/mappers/order-summary/order-details-mapper';
import updateOrderStatusMapper from '@shared/services/mappers/order-summary/update-order-status-mapper';
import paymentMapper from '@shared/services/mappers/payment-mapper';
// State
import { OrderSummaryQuery } from '@shared/states/order-summary/order-summary.query';
import { OrderSummaryStore } from '@shared/states/order-summary/order-summary.store';
import { UpdateOrderStatusModel } from '@shared/models/update-order-status.model';
import { UserGeoLocation } from '@shared/interfaces/geolocation.interface';

@Injectable({
  providedIn: 'root'
})
export class OrderDataService {
  baseUrl = this.http.baseUrl + '/api';

  constructor(
    private http: BaseHttp,
    private orderSummaryStore: OrderSummaryStore,
    private orderSummaryQuery: OrderSummaryQuery
  ) {}

  getOrderDetails(id: ID): Observable<OrderDetailsUiModel> {
    const url = this.baseUrl + `/CourierOrder/order/${id}`;
    const request$ = this.http.get<APIResponse<OrderDetailsModel>>(url).pipe(
      map((response) => {
        const orderDetailsUiModel = orderDetailsMapper.mapToUiModel(
          response.data
        );
        return orderDetailsUiModel;
      })
    );
    return request$;
  }

  getCheckout(orderId: ID): Observable<PaymentUiModel> {
    const url = this.baseUrl + `/order/${orderId}/checkout`;
    const request$ = this.http.get<APIResponse<PaymentModal>>(url).pipe(
      map((response) => {
        const paymentUiModel: PaymentUiModel = paymentMapper.mapToUiModel(
          response.data
        );
        return paymentUiModel;
      })
    );

    return request$;
  }

  updateOrderStatus(order: UpdateOrderStatusUiModel): Observable<unknown> {
    const url = this.baseUrl + '/CourierOrder/order/status';
    const orderData = updateOrderStatusMapper.mapToModel(order);
    return this.http.put<APIResponse<UpdateOrderStatusModel>>(url, orderData);
  }

  getOrderSummary(
    id: ID,
    forceUpdate = false
  ): Observable<OrderSummaryUiModel> {
    const orderDetails = this.getOrderDetails(id);
    const checkout = this.getCheckout(id);
    const request$ = forkJoin({ orderDetails, checkout }).pipe(
      map((res) => {
        const mappedOrderDetails = {
          ...res.orderDetails,
          checkout: res.checkout
        };
        this.orderSummaryStore.upsert(
          mappedOrderDetails.id,
          mappedOrderDetails
        );
        return mappedOrderDetails;
      })
    );
    return this.orderSummaryQuery.hasEntity(id) && !forceUpdate
      ? this.orderSummaryQuery.selectEntity(id)
      : request$;
  }

  getClientVerificationCode(id: ID): Observable<string> {
    return this.orderSummaryQuery.selectEntity(
      id,
      ({ orderDetails }) => orderDetails.verifyToken
    );
  }

  resendOtp(order: MarkOrderInProgressUiModel): Observable<ResendSmsModel> {
    const url = this.baseUrl + '/Order/otptoken/resend';
    const request$ = this.http
      .put<APIResponse<ResendSmsModel>>(url, order)
      .pipe(
        map((res) => {
          if (!!res && res.data) {
            this.orderSummaryStore.update(order.orderId, (entity) => {
              return {
                orderDetails: {
                  ...entity.orderDetails,
                  verifyToken: res.data.verifyToken
                }
              };
            });
            return res.data;
          }
        })
      );
    return request$;
  }

  saveVerificationLocation(OrderId: ID, location: UserGeoLocation): void {
    if (!!location) {
      this.orderSummaryStore.update(OrderId, () => {
        return { verificationLocation: location };
      });
    }
  }
}
