import { ErrorHandler, Injectable, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { ComponentHelperService } from '@shared/services/helpers/components/component-helper/component-helper.service';
import { RoutesConfigService } from '@shared/services/helpers/routes/routes-config/routes-config.service';
import { MfNavigationService } from '@shared/services/helpers/routes/navigation/mf-navigation.service';
@Injectable({
  providedIn: 'root'
})
export class ErrorHandleService implements ErrorHandler {
  constructor(
    private services: ComponentHelperService,
    private navigation: MfNavigationService,
    private routerConfigService: RoutesConfigService,
    private router: Router
  ) {}

  handleError(err: string): void {
    const error = new TypeError(err);
    const routeConfig = this.routerConfigService.getRouteConfigByURL(
      this.router.url
    );
    let pageName =
      routeConfig && routeConfig?.PAGE_NAME
        ? routeConfig.PAGE_NAME
        : this.navigation.getPageName();

    pageName = pageName
      .split(' ')
      .join('_')
      .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '_');

    if (isDevMode()) {
      console.error((error && error.message) || 'unknown');
    }

    //TODO call trackError in trackingService
    // this.trackingService.trackError(`${TrackErrorPrefix.ErrorJs}_${pageName}`, {
    //   message: (error && error.message) || 'unknown'
    // });
  }

  handleServerErrorNavigation(): void {
    const redirectionPath = this.services.navigation.getModuleRootPath();
    this.services.navigation.navigate(['/server-error'], {
      redirectionPath: redirectionPath
    });
  }
}
